import React, { useState, useEffect } from "react";
import {
  getCommentsByCourseId,
  getPublicCommentsByCourseId,
  postComment,
} from "../../services/endpoints/general/comments";
import { getToken } from "../../services/auth";
import { getCurrentUser } from "../../utils/getUser";
import { stripTags } from "../../utils/manipulateString";
import "./styles.css";

const Anotacoes = ({ idCurso }) => {
  const [comments, setComments] = useState([]);
  const [actualComment, setActualComment] = useState("");
  const [reaload, setReload] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await (getToken()
          ? getCommentsByCourseId(idCurso)
          : getPublicCommentsByCourseId(idCurso));
        setComments(response.data || []);
      } catch (error) {
        console.error("Anotacoes.getData: ", error);
      }
    };

    getData();
  }, [idCurso, reaload]);

  const handleClick = async (event) => {
    event.preventDefault();
    const body = {
      user_iri: `/users/${getCurrentUser().id}`,
      parent_iri: `/lms/courses/${idCurso}`,
      description: actualComment,
    };
    try {
      await postComment(body);
      setActualComment("");
      setReload(!reaload);
      alert("Comentário registrado com sucesso!");
    } catch (error) {
      alert("Houve algum problema ao registrar!");
    }
  };
  return (
    <>
      {comments && (
        <div className='anotacoes'>
          <h1 className='anotacoes-title'>
            {comments.length > 0 ? "Comentários" : "Sem Comentários"}
          </h1>
          {getToken() && (
            <div className='anotacoes-comentar'>
              <form
                className='anotacoes-comentar-form'
                onSubmit={(e) => handleClick(e)}
              >
                <img className='anotacoes-comentar-form-image' src='' alt='' />
                <input
                  className='anotacoes-comentar-form-input'
                  id='anotacoes'
                  type='text'
                  name='anotacoes'
                  placeholder='Escreva uma anotação'
                  required
                  onChange={(e) => setActualComment(e.target.value)}
                />
                <button
                  className='anotacoes-comentar-form-button'
                  type='submit'
                >
                  Salvar
                </button>
              </form>
            </div>
          )}
          {comments.length > 0 && (
            <div className='anotacoes-comentarios'>
              {comments.map((comment, index) => (
                <div className='anotacoes-comentarios-comentario' key={index}>
                  {comment.user.file && (
                    <img
                      className='anotacoes-comentarios-comentario-image'
                      src={comment.user.file.urlPublica}
                      alt=''
                    />
                  )}
                  <div className='anotacoes-comentarios-comentario-info'>
                    {comment.user.name && (
                      <p className='anotacoes-comentarios-comentario-info-nome'>
                        {comment.user.name}
                      </p>
                    )}
                    {comment.created_at && (
                      <div className='anotacoes-comentarios-comentario-info-data'>
                        {comment.created_at.split(" ")[0]}
                      </div>
                    )}

                    {comment.description && (
                      <div
                        className='anotacoes-comentarios-comentario-info-texto'
                        dangerouslySetInnerHTML={{
                          __html: stripTags(comment.description),
                        }}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Anotacoes;
