import React, { useEffect, useState } from "react";
import { Container, Icon, Title } from "./styles";

const SupportMaterial = ({ supportMaterial }) => {
  const [supportContent, setSupportContent] = useState({});

  useEffect(() => {
    setSupportContent(
      (supportMaterial &&
        supportMaterial.support_material_contents &&
        supportMaterial.support_material_contents[0] &&
        supportMaterial.support_material_contents[0].file &&
        supportMaterial.support_material_contents[0].file) ||
        {}
    );
  }, [supportMaterial]);

  const getFileFormat = () => {
    const formatFile = supportContent.content_type || "";

    if (formatFile.includes("pdf")) {
      return <Icon className='far fa-file-pdf' />;
    } else if (formatFile.includes("sheet")) {
      return <Icon className='far fa-file-excel' />;
    } else if (formatFile.includes("msword")) {
      return <Icon className='far fa-file-word' />;
    } else if (
      formatFile.includes("jpg") ||
      formatFile.includes("png") ||
      formatFile.includes("jpeg")
    ) {
      return <Icon className='far fa-file-image' />;
    } else if (formatFile.includes("mp4") || formatFile.includes("avi")) {
      return <Icon className='far fa-file-video' />;
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    window.open(supportContent.urlPublica, "_blank");
  };

  if (!supportMaterial) return null;
  return (
    <Container onClick={(e) => handleClick(e)}>
      {getFileFormat()}
      <Title>{supportContent.name || ""}</Title>
    </Container>
  );
};

export default SupportMaterial;
