import React from "react";
import SupportMaterial from "./SupportMaterial";
import "./styles.css";

const Material = ({ supportMaterials }) => {
  return (
    <div className='curso-show-info-content-material'>
      {supportMaterials &&
        supportMaterials.length > 0 &&
        supportMaterials.map((material) => (
          <SupportMaterial
            supportMaterial={material && material.support_material}
            key={material.id}
          />
        ))}
    </div>
  );
};

export default Material;
